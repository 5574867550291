import type { SVGProps } from 'react';

export function Bank(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5094 15.4628C16.5094 15.6903 16.4639 15.9179 16.3729 16.0999C16.2819 16.282 16.1909 16.464 16.0543 16.6461C15.9178 16.7826 15.7358 16.9191 15.5537 17.0102C15.3717 17.1012 15.1441 17.1467 14.9166 17.1467H3.03816C2.8106 17.1467 2.58305 17.1012 2.401 17.0102C2.17345 16.9191 1.9914 16.8281 1.85487 16.6461C1.71834 16.5095 1.5818 16.3275 1.49078 16.0999C1.39976 15.9179 1.35425 15.6903 1.35425 15.4628V7.40608C1.35425 6.95097 1.53629 6.54137 1.85487 6.22279C2.17345 5.90421 2.58305 5.72217 3.03816 5.72217H14.9621C15.4172 5.72217 15.8268 5.90421 16.1454 6.22279C16.4639 6.54137 16.646 6.95097 16.646 7.40608V8.86368H12.4134C11.9583 8.86368 11.5487 9.00021 11.2302 9.31879C10.9116 9.63737 10.7295 10.0015 10.7295 10.5021C10.7295 10.8207 10.8206 11.0937 10.9116 11.3213C11.0026 11.5488 11.1846 11.7309 11.4122 11.8674C11.6398 12.0495 11.9583 12.1405 12.3679 12.1405H16.5094V15.4628ZM11.5942 10.5021C11.5942 10.2745 11.6853 10.0925 11.8218 9.91043C11.9583 9.7739 12.1859 9.68288 12.4134 9.68288C12.641 9.68288 12.823 9.7739 13.0051 9.91043C13.1416 10.047 13.2326 10.2745 13.2326 10.5021C13.2326 10.7296 13.1416 10.9117 13.0051 11.0937C12.8686 11.2758 12.641 11.3213 12.4134 11.3213C12.1859 11.3213 12.0038 11.2303 11.8218 11.0937C11.6853 10.9572 11.5942 10.7751 11.5942 10.5021Z"
        fill="currentColor"
      />
      <path
        d="M14.32 4C14.6573 4 14.8978 3.6729 14.7973 3.35095L14.1765 1.36292C14.0935 1.09751 13.7902 0.943703 13.4992 1.01921L5.79604 3.016C5.22776 3.16331 5.33444 4 5.92151 4H14.32Z"
        fill="hsl(var(--nextui-primary)"
      />
    </svg>
  );
}

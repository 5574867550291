import { WalletTypeEnum } from '@/enums/businessEnum';
import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import { useLocalStorage } from '@/hooks/useStorage';
import { cn } from '@/utils';
import RenderUtil from '@/utils/RenderUtil';
import { history } from '@@/core/history';
import { useModel } from '@@/exports';
import { Checkbox, Divider, Image } from '@nextui-org/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormatUtils } from '@/utils/format';

interface IProps {
  hiddenCheckBox?: boolean;
  isShow: boolean;
}

export default ({ hiddenCheckBox = false }: IProps) => {
  const { t } = useTranslation();
  const { coinsFunds, renderFunds } = useModel('user');
  const { coinPrecision } = useModel('appInfo');

  const { exchangeConverFunds, currentExchangeFunds, maskPrice } =
    useModel('exchange');

  //是否隐藏小于1美元金额
  const [isSelected, setIsSelected] = useLocalStorage({
    key: CacheEnum.APP_HIDE_LOW_AMOUNT,
    default: {},
  });
  const renderList = useMemo(() => {
    const walletList = coinsFunds?.walletList
      ?.map((item: any) => {
        // 查找socket推送的币币列表
        const findItem = renderFunds?.coin?.walletList?.find?.(
          (fundsItem: any) => {
            return fundsItem?.name === item?.name;
          },
        );
        return {
          ...item,
          availableBalance: findItem?.ab ?? item?.availableBalance,
          convertUsd: findItem?.abu ?? item?.convertUsd,
          freezeBalance: findItem?.fb ?? item?.freezeBalance,
          freezeBalanceU: findItem?.fbu,
        };
      });

    if (!isSelected) {
      return walletList;
    }
    return walletList?.filter((item: any) => {
      return parseInt(item?.convertUsd.toString() + item?.freezeBalanceU, 0) > 1;
    });

  }, [coinsFunds, isSelected, renderFunds]);

  return (
    <>
      <div className="w-full">
        <div className="mb-2 flex justify-between items-center">
          {!hiddenCheckBox && (
            <div
              className="flex px-4 items-center  bg-background justify-between text-base
               text-backContrastColor"
            >
              <span>{t('资产')}</span>
            </div>
          )}
          {!hiddenCheckBox && (
            <div className="px-4">
              <Checkbox
                isSelected={isSelected}
                onValueChange={setIsSelected}
                color="warning"
                size="sm"
              >
                <span className="text-xs text-auxiliaryTextColor">
                  {t('隐藏不足1美元的资产')}
                </span>
              </Checkbox>
            </div>
          )}
        </div>
        <div className="px-4" key={isSelected as any}>
          {renderList?.map((item: any, index: number) => (
            <div
              key={item?.id}
              onClick={() => {
                history.push(
                  PageEnum.COIN_FUNDS_DETAIL +
                  '?coinId=' +
                  item?.coinId +
                  '&walletType=' +
                  WalletTypeEnum.COIN,
                );
              }}
            >
              <div
                className={cn(
                  'flex justify-between items-center text-base  text-titleColor',
                  {
                    'mt-4': index !== 0,
                  },
                )}
              >
                <div>
                  <div className="flex items-center">
                    <Image
                      className="w-[26px] h-[26px] rounded-full object-cover"
                      src={item?.icon}
                    />
                    <div className="ml-2">{item?.alias ?? item?.name}</div>
                  </div>
                </div>
                <div>
                  {maskPrice(
                    !item?.availableBalance
                      ? '0'
                      : RenderUtil.FormatAmount(
                        item?.availableBalance,
                        FormatUtils.getPrecision(item?.name,coinPrecision),
                      ).toString(),
                    false,
                    '0.00',
                  )}
                </div>
              </div>

              <div className="ml-[34px] text-auxiliaryTextColor text-xs  flex justify-between">
                <span>{t('可用')}</span>
                <span>
                  {exchangeConverFunds({
                    price: item?.convertUsd,
                    // currency: currentExchangeFunds?.exchange,
                    isShowCoinName: false,
                    defaultVal: '0.00',
                  })}
                </span>
              </div>
              <div className="pl-[34px] w-full flex mb-3 mt-2 justify-between text-auxiliaryTextColor text-xs">
                <span>{t('冻结')}</span>
                <span>
                  {maskPrice(
                    !item?.freezeBalance
                      ? '0'
                      : RenderUtil.FormatAmount(
                        item?.freezeBalance,
                        6,
                      ).toString(),
                    false,
                    '0.00',
                  )}
                </span>
              </div>
              <Divider className="!bg-backgroundAuxiliaryColor/80" />
            </div>
          ))}
        </div>

      </div>
    </>
  );
};

import BaseTabs from '@/components/base/baseTabs';
import FundingAccountInformation from '@/pages/funds/index/components/FundingAccountInformation';
import FundingCurrencyList from '@/pages/funds/index/components/FundingCurrencyList';
import { useModel } from '@@/exports';
import { Divider } from '@nextui-org/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FundingCardList from './FundingCardList';
import FundsUStandard from './FundsUStandard';
import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import { FormatUtils } from '@/utils/format';

/**
 * 法币账户
 */
export default () => {
  const { t } = useTranslation();
  const { isMobile } = useModel('system');

  const options = [
    {
      text: t('账户总览'),
      value: '1',
    },
    {
      text: t('现货账户'),
      value: '0',
    },
  ];
  const [currentTab, setCurrentTab] = useState('1');

  return (
    <div >
      <div className="mt-2 relative">
        {/*资金信息*/}
        <FundsUStandard />
        {/*快捷入口 一排图标*/}
        <FundingCardList />
        {/*分割线*/}
        {isMobile && <Divider className="my-3 !bg-backgroundAuxiliaryColor/80" />}
        {/*币种和账户*/}
        <BaseTabs
          tabsPropsProps={{
            className: 'w-full',
            classNames: {
              tab: 'first:!pl-3',
              tabList: 'pb-0',
            },
          }}
          value={currentTab}
          onChange={setCurrentTab}
          options={options}
        />
        <div className="pt-2">
          {currentTab === '0' && (
            <FundingCurrencyList hiddenCheckBox isShow={true} />
          )}

          {/* 币币账户 */}
          {currentTab === '1' && <FundingAccountInformation />}
        </div>
      </div>

    </div>
  );
};

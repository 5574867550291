import { MaterialSymbolsAssignmentRounded } from '@/assets/icons/funds/MaterialSymbolsAssignmentRounded';
import { WalletTypeEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import FundsContractUStandard from '@/pages/funds/index/components/FundsContractUStandard';
import ContractOrderList from '@/pages/order/index/components/ContractOrderList';
import { history } from '@@/core/history';
import { useModel } from '@umijs/max';
import { useEffect, useState } from 'react';

/**
 * 合约 资金页面
 */
export default () => {
  const [orderKey, setOrderKey] = useState(1);
  const reloadOrder = () => {
    setOrderKey((old) => old + 1);
  };

  useEffect(() => {
    reloadOrder();
  }, []);

  const { orderKey: appOrderKey } = useModel('system');

  return (
    <>
      <div className="mt-2 relative">
        <FundsContractUStandard />

        <div className="md:flex-1 mt-4">
          <div  className="min-h-[40vh]">
            <ContractOrderList
              siftNode={
                // 筛选按钮替换为进入订单页面
                <MaterialSymbolsAssignmentRounded
                  onClick={() => {
                    history.push(
                      PageEnum.ORDER + '?type=' + WalletTypeEnum.CONTRACT,
                    );
                  }}
                  width="1.3em"
                  height="1.3em"
                  className="cursor-pointer text-foreground "
                />
              }
            ></ContractOrderList>
          </div>
        </div>
      </div>
    </>
  );
};

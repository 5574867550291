import type { SVGProps } from 'react';

export function FlashExchange(props: SVGProps<SVGSVGElement>) {
  return (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8.99765 2.332C12.6807 2.332 15.6663 5.31667 15.6663 8.99867C15.6663 12.6807 12.6807 15.6653 8.99765 15.6653C5.31458 15.6653 2.32904 12.6807 2.32904 8.99867C2.32904 5.31667 5.31458 2.332 8.99765 2.332ZM8.99765 6.574L6.57228 8.99867L8.99765 11.4233L11.423 8.99867L8.99765 6.574Z"
            fill="currentColor"/>
        <path d="M1 17L5.36394 16.1287C3.85978 15.3599 2.63617 14.1369 1.86692 12.6333L1 17Z" fill="hsl(var(--nextui-primary)"/>
        <path d="M17 1L12.6361 1.87133C14.1402 2.64007 15.3638 3.86309 16.1331 5.36667L17 1Z" fill="hsl(var(--nextui-primary)"/>
      </svg>


  );
}

import { cn } from '@/utils';
import { useState } from 'react';
import { useUpdateEffect } from 'ahooks';

interface IProps {
  currentTab: string;
  tabs: {
    text: string;
    value: string;
    component: any;
    props?:any
  }[];
}

export default ({ currentTab, tabs }: IProps) => {
  // 保存已点击的key
  const [tabsClicked, setTabsClicked] = useState<any[]>([currentTab]);
  useUpdateEffect(() => {
    if (!tabsClicked.includes(currentTab)) {
      setTabsClicked([...tabsClicked, currentTab]);
    }
  }, [currentTab]);

  return <>
    {tabs.map(item => {
      return <div key={item?.value}>
        {
          tabsClicked.includes(item?.value) && <div className={cn(``, {
            'hidden': currentTab !== item?.value,
          })}>
            <item.component {...(item.props ?? {})}/>
          </div>
        }
      </div>;
    })}
  </>;
}
import BasePullDownToRefresh from '@/components/base/basePullDownToRefresh';
import BaseTabs from '@/components/base/baseTabs';
import FundsCapitalAccount from '@/pages/funds/index/components/FundsCapitalAccount';
import FundsOverview from '@/pages/funds/index/components/FundsOverview';
import { useModel } from '@@/exports';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FundingContract from './components/FundingContract';
import { Divider } from '@nextui-org/react';
import CacheList from '@/pages/funds/orderHistory/index/components/CacheList';
import RechargeRecordList from '@/pages/funds/orderHistory/index/components/RechargeRecordList';

export default () => {
  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = useState('1');
  const changeTab = (value: any) => {
    setTabSelected(value);
  };
  const tabOptions = [
    {
      text: t('总览'),
      value: '1',
      component: FundsOverview,
    },
    {
      text: t('现货'),
      value: '2',
      component: FundsCapitalAccount,

    },
    {
      text: t('合约'),
      value: '3',
      component: FundingContract,
    },
  ];
  const { fundingOverviewSocketMapReq } = useModel('user');

  return (
    <div>
      <BaseTabs
        value={tabSelected}
        onChange={changeTab as any}
        options={tabOptions}
        variant="underlined"
        scrollShadowProps={{
          className:
            'sticky sm:relative top-0  sm:top-0 z-[1000] sm:z-[1] bg-background left-0 w-full select-none',
        }}
        classNames={{
          // tabs:"!font-bold"
        }}
        tabsPropsProps={{
          classNames: {
            tab: 'px-0 px-2 text-[18px] !pb-0',
            tabList: 'pb-0 gap-0 pb-1 pl-2',
            tabContent: 'group-data-[selected=true]:text-backContrastColor',
            cursor: 'hidden',
            panel: 'p-0',
          },
        }}
      />
      <Divider className="!bg-backgroundAuxiliaryColor/80" />

      <BasePullDownToRefresh
        refreshFn={async () => {
          fundingOverviewSocketMapReq();
          return true;
        }}
      >
        <CacheList currentTab={tabSelected} tabs={tabOptions} />
      </BasePullDownToRefresh>

    </div>
  );
};

import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import { IonEyeOff } from '@/assets/icons/funds/IonEyeOff';
import { MaterialSymbolsAssignmentRounded } from '@/assets/icons/funds/MaterialSymbolsAssignmentRounded';
import { OouiEye } from '@/assets/icons/funds/OouiEye';
import FeaturesExchangeSelect from '@/components/features/featuresExchangeSelect';
import { WalletTypeEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import RechargeJumpModal from '@/pages/funds/index/components/RechargeJumpModal';
import { returnExperiencePriceApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import ProjectSetting from '@/setting/projectSetting';
import { history } from '@@/core/history';
import { Button } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMemoShowFunds } from '@/hooks/useMemoShowFunds';

//合约钱包
export default () => {
  const { t } = useTranslation();
  const { exchangeConverFunds, currentExchangeFunds, isShow, setIsShow, exchangeSetConvert } =
    useModel('exchange');
  const {
    user,
    requestUserInfo,
    renderFunds,
    contractProfitAndLoss,
    contractAvailableFunds,
  } = useModel('user');

  // 归还体验金 接口
  const {
    run: returnExperiencePriceReq,
    loading: returnExperiencePriceLoading,
  } = useReq(returnExperiencePriceApi, {
    manual: true,
    loadingDefault: false,
    onSuccess: async (data) => {
      toast.success(t('操作成功'));
      // 重新请求用户信息 【获取最近用户状态：experienceStatus】 【0 已赠送 1 已归还 -1待归还】
      await requestUserInfo();
    },
  });
  // 归还体验金
  const handleClickReturnTrialMoney = () => {
    returnExperiencePriceReq();
  };
  const totalFunds = useMemoShowFunds(() => {
    return renderFunds?.contract?.total + contractProfitAndLoss;
  }, [renderFunds?.contract?.total, contractProfitAndLoss]);

  return (
    <>
      <div>
        <div
          className="px-4 md:min-w-[20vw] sm:border-1 sm:border-backgroundAuxiliaryColor sm:rounded-md sm:px-3 pt-1 ">
          <div className="flex justify-between">
            <div className="flex items-center pb-2">
              <span className="text-backContrastColor mr-2  border-b-1 border-dashed border-backgroundAuxiliaryColor ">
                {t('保证金')}
              </span>
              {isShow && (
                <OouiEye
                  className="text-auxiliaryTextColor text-base"
                  onClick={() => {
                    setIsShow(false);
                  }}
                />
              )}
              {!isShow && (
                <IonEyeOff
                  className="text-auxiliaryTextColor text-base"
                  onClick={() => {
                    setIsShow(true);
                  }}
                />
              )}
            </div>
            <MaterialSymbolsAssignmentRounded
              onClick={() => {
                history.push(
                  PageEnum.ORDER_HISTORY +
                  '?walletType=' +
                  WalletTypeEnum.CONTRACT,
                );
              }}
              width="1.5em"
              height="1.5em"
              className="cursor-pointer text-foreground pt-1"
            />
          </div>
          <div className="flex items-end text-backContrastColor">
            <div className="text-xl mr-1 flex items-center">
              {/* 总资金 */}
              <div className="text-[28px]">
                {/* 总资产 */}
                {exchangeConverFunds({
                  price: totalFunds,
                  defaultVal: '--',
                })}
              </div>
              <div className="text-sm flex items-center">
                {/* 汇率下拉 */}
                <FeaturesExchangeSelect />
              </div>
            </div>
          </div>

          <div className="text-auxiliaryTextColor flex items-center">
            ≈{' '}
            {exchangeSetConvert({
              price: totalFunds,
              isJoin: true,
            })}
            <span className="ml-1"></span>
          </div>
          <div className="flex justify-between mt-3 text-xxs">
            <div className="text-auxiliaryTextColor ">
              <div>{t('钱包余额')}</div>
              <div className="text-backContrastColor mt-2">
                <span>
                  {exchangeConverFunds({
                    price: contractAvailableFunds,
                    currency: currentExchangeFunds?.exchange,
                    isShowCoinName: true,
                  })}
                </span>
              </div>
              <div className="text-auxiliaryTextColor flex items-center">
                ≈{' '}
                {exchangeSetConvert({
                  price: contractAvailableFunds,
                  isJoin: true,
                })}
                <span className="ml-1"></span>
              </div>
            </div>
            <div className="text-auxiliaryTextColor mr-10 text-xxs">
              <div>{t('未实现盈亏')}</div>
              <div className="text-backContrastColor mt-2">
                <span>
                  {exchangeConverFunds({
                    price: contractProfitAndLoss,
                    currency: currentExchangeFunds?.exchange,
                    isShowCoinName: true,
                  })}
                </span>
              </div>
              <div className="text-auxiliaryTextColor flex items-center">
                ≈{' '}
                {exchangeSetConvert({
                  price: contractProfitAndLoss,
                  isJoin: true,
                })}
                <span className="ml-1"></span>
              </div>
            </div>
          </div>

          <div
            className="grid grid-cols-3 gap-x-[10px]
             mt-3 text-backContrastColor"
          >
            <RechargeJumpModal>
              <button className="mainColorButton text-[14px] !rounded-md h-[40px] !py-0">
                {t('充值')}
              </button>
            </RechargeJumpModal>
            <Button
              onClick={() => {
                history.push(PageEnum.WITHDRAW);
              }}
              className="mainColorButton !rounded-md h-[40px] !py-0 !text-titleColor !bg-backgroundAuxiliaryColor"
            >
              {t('提现')}
            </Button>
            <Button
              onClick={() => {
                history.push(PageEnum.TRANSFER);
              }}
              className="mainColorButton !rounded-md h-[40px] !py-0 !text-titleColor !bg-backgroundAuxiliaryColor"
            >
              {t('划转')}
            </Button>
          </div>


          {/* 归还体验金 */}
          {+user?.experienceStatus === -1 && (
            <div className="mt-3 w-full flex  justify-start mb-4">
              <Button
                size="sm"
                className=" w-fit min-w-1/3 !rounded-md h-[40px] mainColorButton text-[14px]  !py-1"
                // color="primary"
                spinner={<LoadingSvg />}
                isLoading={returnExperiencePriceLoading}
                onClick={handleClickReturnTrialMoney}
              >
                {t('归还体验金')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
 
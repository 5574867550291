import type { SVGProps } from 'react';

export function PersonalCenter(props: SVGProps<SVGSVGElement>) {
  return (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" { ...props}>
        <path
            d="M9.89552 8.50746C11.4179 7.79105 12.4925 6.26866 12.4925 4.47761C12.4925 2.01493 10.4776 0 7.97015 0C5.46269 0 3.44776 2.01493 3.44776 4.47761C3.44776 6.22388 4.47761 7.74627 5.95523 8.50746C2.77612 9.13433 0 11.5075 0 15.6716H15.7612C15.7612 11.597 13.0299 9.22388 9.89552 8.50746Z"
            fill="currentColor"/>
        <path
            d="M16.394 6.22388H14.2403C13.8455 6.22388 13.5224 6.62687 13.5224 7.11941C13.5224 7.61194 13.8455 8.01493 14.2403 8.01493H16.394C16.7889 8.01493 17.112 7.61194 17.112 7.11941C17.112 6.62687 16.7889 6.22388 16.394 6.22388Z"
            fill="hsl(var(--nextui-primary)"/>
        <path
            d="M17.1045 2.05472H14.4179C13.9254 2.05472 13.5224 2.4577 13.5224 2.95024C13.5224 3.44278 13.9254 3.84576 14.4179 3.84576H17.1045C17.597 3.84576 18 3.44278 18 2.95024C18 2.4577 17.597 2.05472 17.1045 2.05472Z"
            fill="hsl(var(--nextui-primary)"/>
      </svg>

  );
}

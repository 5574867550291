import { IonEyeOff } from '@/assets/icons/funds/IonEyeOff';
import { MaterialSymbolsAssignmentRounded } from '@/assets/icons/funds/MaterialSymbolsAssignmentRounded';
import { OouiEye } from '@/assets/icons/funds/OouiEye';
import FeaturesExchangeSelect from '@/components/features/featuresExchangeSelect';
import { WalletTypeEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import FundingCurrencyList from '@/pages/funds/index/components/FundingCurrencyList';
import RechargeJumpModal from '@/pages/funds/index/components/RechargeJumpModal';
import ProjectSetting from '@/setting/projectSetting';
import { Button, Divider } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import { useReq } from '@/services/net/request';
import { returnExperiencePriceApi } from '@/services/api/requestApi';
import { toast } from '@/layouts/components/ToastMessage';

//现货 资金页面
export default () => {
  const { t } = useTranslation();
  const { isMobile } = useModel('system');
  const { renderFunds,user } = useModel('user');
  const {
    exchangeConverFunds,
    exchangeSetConvert,
    isShow,
    setIsShow,
  } = useModel('exchange');
  const [, setConvertCoin] = useState(ProjectSetting.APP_DEFAULT_CURRENCY);


  return (
    <div>
      <div className="px-4 mt-2 md:min-w-[20vw]  md:border-borderColor  py-1">
        <div className="flex justify-between">
          <div className="flex items-center pb-2">
            <span className="text-backContrastColor mr-2 border-b-1 border-dashed border-backgroundAuxiliaryColor ">
              {t('总资产')}
            </span>
            {isShow && (
              <OouiEye
                className="text-auxiliaryTextColor text-base"
                onClick={() => {
                  setIsShow(false);
                }}
              />
            )}
            {!isShow && (
              <IonEyeOff
                className="text-auxiliaryTextColor text-base"
                onClick={() => {
                  setIsShow(true);
                }}
              />
            )}
          </div>
          <MaterialSymbolsAssignmentRounded
            onClick={() => {
              history.push(
                PageEnum.ORDER_HISTORY + '?walletType=' + WalletTypeEnum.COIN,
              );
            }}
            width="1.5em"
            height="1.5em"
            className="cursor-pointer text-foreground pt-1"
          />
        </div>
        <div className="flex items-end text-backContrastColor">
          <div className="text-xl mr-1 flex items-center">
            <div className="text-[28px]">
              {/* 总资产 */}
              {exchangeConverFunds({
                price: renderFunds?.coin?.total,
                defaultVal: '--',
              })}
            </div>
            <div className="text-sm flex items-center">
              {/* 汇率下拉 */}
              <FeaturesExchangeSelect />
            </div>
          </div>
        </div>
        <div className="text-auxiliaryTextColor">
          ≈{' '}
          {exchangeSetConvert({
            price: renderFunds?.coin?.total,
            isJoin: true,
          })}
        </div>
        {<div
          className="grid grid-cols-3 gap-x-[10px]
             mt-3 text-backContrastColor"
        >
          <RechargeJumpModal>
            <button className="mainColorButton text-[14px] !rounded-md h-[40px] !py-0">
              {t('充值')}
            </button>
          </RechargeJumpModal>
          <Button
            onClick={() => {
              history.push(PageEnum.WITHDRAW);
            }}
            className="mainColorButton !rounded-md h-[40px] !py-0 !text-backContrastColor !bg-backgroundAuxiliaryColor"
          >
            {t('提现')}
          </Button>
          <Button
            onClick={() => {
              history.push(PageEnum.TRANSFER);
            }}
            className="mainColorButton !rounded-md h-[40px] !py-0 !text-backContrastColor !bg-backgroundAuxiliaryColor"
          >
            {t('划转')}
          </Button>
        </div>}
      </div>
      {isMobile && <Divider className="my-4 !bg-backgroundAuxiliaryColor" />}

      <FundingCurrencyList isShow={isShow} />
    </div>
  );
};

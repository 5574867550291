import { useGlobalPrompt } from '@/components/base/basePromptWindow';
import FeaturesRenderText from '@/components/features/featuresRenderText';
import { GetTextEnum } from '@/enums/businessEnum';
import { getPrivacyAgreementApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import ProjectSetting from '@/setting/projectSetting';
import { useModel } from '@@/exports';
import { Divider, ScrollShadow } from '@nextui-org/react';
import { useInViewport, useMount } from 'ahooks';
import { useTranslation } from 'react-i18next';
import { useMemoShowFunds } from '@/hooks/useMemoShowFunds';
import { FormatUtils } from '@/utils/format';
import ReactDOM from 'react-dom';
import { useRef } from 'react';
import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';

const Uid = () => {
  const { user } = useModel('user');

  return ReactDOM.createPortal(
    <div className="fixed bottom-[74px] left-1/2 -translate-x-1/2  flex items-center justify-center">
      <div className="!w-fit px-3 py-1 text-[12px] text-auxiliaryTextColor  rounded-md   flex items-center gap-1"
           onClick={(e) => {
             FormatUtils.copyText(user?.account);
             e.stopPropagation();
           }}>
        <span>UID:</span>
        <span>{user?.account}</span>
         <PhCopyFill
          className="text-auxiliaryTextColor"
          onClick={(e) => {
            FormatUtils.copyText(user?.account);
            e.stopPropagation();
          }}
        />
      </div>
    </div>,
    document.body,
  );
};

export default () => {
  const { t } = useTranslation();
  const { exchangeConverFunds, currentExchangeFunds, exchangeSetConvert } = useModel('exchange');
  const { renderFunds, contractProfitAndLoss } = useModel('user');
  //获取冻结金额说明
  const {
    data: descriptionOfFrozenAmount = false,
    runAsync: getDescriptionOfFrozenAmountReq,
  } = useReq(getPrivacyAgreementApi, {
    manual: true,
  });
  useMount(() => {
    getDescriptionOfFrozenAmountReq({
      type: GetTextEnum.FREEZE_NOTICES,
    });
  });
  // 提示窗
  const { openPrompt } = useGlobalPrompt();

  // 合约总资金
  const totalContractFunds = useMemoShowFunds(() => {
    return renderFunds?.contract?.total + contractProfitAndLoss;
  }, [renderFunds?.contract?.total, contractProfitAndLoss]);

  const ref = useRef(null);
  const [inViewport] = useInViewport(ref);


  return (
    <div className="px-4 pt-1" ref={ref}>
      <div>
        <div className="flex justify-between text-backContrastColor ">
          <span className="text-base">{t('现货')}</span>
          <span className="text-sm">
            {/*币币账户资产，转换为当前选中的汇率*/}
            {exchangeConverFunds({
              price: renderFunds?.coin?.total,
              currency: currentExchangeFunds?.exchange,
              isShowCoinName: true,
            })}
          </span>
        </div>
        <div className="flex justify-between text-auxiliaryTextColor  text-xs ">
          <span></span>
          <span>
            {/*美元展示币币账户资产*/}
            {exchangeSetConvert({
              price: renderFunds?.coin?.total,
              isJoin: true,
            })}
          </span>
        </div>

        <Divider className="my-3 !bg-backgroundAuxiliaryColor" />
        {/* 合约账户 */}
        <div className="flex justify-between text-backContrastColor text-base">
          <span className="text-base">{t('合约')}</span>
          <span className="text-sm">
            {/*合约账户资产，转换为当前选中的汇率*/}
            {exchangeConverFunds({
              price: totalContractFunds,
              currency: currentExchangeFunds?.exchange,
              isShowCoinName: true,
            })}
          </span>
        </div>
        <div className="flex justify-between text-auxiliaryTextColor  text-xs ">
          <span></span>
          <span>
            {/*美元展示合约账户*/}
            {exchangeSetConvert({
              price: totalContractFunds,
              isJoin: true,
            })}
          </span>
        </div>
        {![0, null, undefined].includes(renderFunds?.contract?.totalFrozenAmount) &&
          <div className="flex justify-between text-auxiliaryTextColor mt-2 text-xs ">
          <span
            className="border-b-1 border-dashed"
            onClick={async () => {
              if (descriptionOfFrozenAmount) {
                openPrompt({
                  showCancel: false,
                  title: t('冻结资产'),
                  content: (
                    <ScrollShadow hideScrollBar className="h-[400px] ">
                      <FeaturesRenderText data={descriptionOfFrozenAmount} />
                    </ScrollShadow>
                  ),
                });
              }
            }}
          >
            {t('冻结资产')}
          </span>
            <span>
            {/*美元展示合约冻结资产*/}
              {exchangeConverFunds({
                price: renderFunds?.contract?.totalFrozenAmount,
                // currency: 'USD',
                currency: ProjectSetting.APP_DEFAULT_CURRENCY,

                isJoin: true,
                len: 8,
              })}
          </span>
          </div>
        }
        <Divider className="my-3 !bg-backgroundAuxiliaryColor" />
      </div>
      {inViewport && <Uid />}

    </div>
  );
};

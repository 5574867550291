import type { SVGProps } from 'react';

export function OnlineCustomerService(props: SVGProps<SVGSVGElement>) {
  return (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M5.18182 7.9C5.18182 8.41215 5.28058 8.91929 5.47246 9.39246C5.66434 9.86563 5.94559 10.2956 6.30014 10.6577C6.65469 11.0199 7.0756 11.3071 7.53885 11.5031C8.00209 11.6991 8.49859 11.8 9 11.8C9.50141 11.8 9.99791 11.6991 10.4612 11.5031C10.9244 11.3071 11.3453 11.0199 11.6999 10.6577C12.0544 10.2956 12.3357 9.86563 12.5275 9.39246C12.7194 8.91929 12.8182 8.41215 12.8182 7.9C12.8182 6.86565 12.4159 5.87367 11.6999 5.14228C10.9838 4.41089 10.0126 4 9 4C7.98736 4 7.01619 4.41089 6.30014 5.14228C5.58409 5.87367 5.18182 6.86565 5.18182 7.9Z"
            fill="currentColor"/>
        <path
            d="M12.913 11.15C14.7655 11.9326 16 13.3691 16 15.4887C16 15.8677 15.531 16.296 14.9436 16.4637C13.6709 16.8264 11.3978 17.0019 9 17C6.75873 16.998 4.68291 16.855 3.36055 16.558C2.62045 16.3903 2 16.1114 2 15.4881C2 13.3691 3.23455 11.9326 5.087 11.15C5.57115 11.7252 6.17174 12.1863 6.84731 12.5014C7.52287 12.8165 8.25733 12.9782 9 12.9752C9.72679 12.9781 10.4459 12.8233 11.1098 12.5212C11.7737 12.2192 12.3673 11.7766 12.8513 11.2228L12.913 11.15Z"
            fill="currentColor"/>
        <path
            d="M9 1C12.682 1 15.6667 3.87807 15.6667 7.42857H14.3333C14.3333 6.0646 13.7714 4.7565 12.7712 3.79202C11.771 2.82755 10.4145 2.28571 9 2.28571C7.58551 2.28571 6.22896 2.82755 5.22876 3.79202C4.22857 4.7565 3.66667 6.0646 3.66667 7.42857H2.33333C2.33333 3.87807 5.318 1 9 1Z"
            fill="hsl(var(--nextui-primary)"/>
        <path
            d="M2.33333 6.14286C3.22222 6.14286 3.66667 6.57143 3.66667 7.42857L3.66667 8.71429C3.66667 9.57143 3.22222 10 2.33333 10C1.44444 10 1 9.57143 1 8.71429V7.42857C1 6.57143 1.44444 6.14286 2.33333 6.14286Z"
            fill="hsl(var(--nextui-primary)"/>
        <path
            d="M15.6667 6.14286C16.5556 6.14286 17 6.57143 17 7.42857V8.71429C17 9.57143 16.5556 10 15.6667 10C14.7778 10 14.3333 9.57143 14.3333 8.71429V7.42857C14.3333 6.57143 14.7778 6.14286 15.6667 6.14286Z"
            fill="hsl(var(--nextui-primary)"/>
      </svg>

  );
}

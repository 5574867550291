import type { SVGProps } from 'react';

export function BUSINESSRECHARGE(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.7142 2.26299C16.7142 1.72334 16.2822 1.28564 15.7496 1.28564H2.25026C1.71764 1.28564 1.28564 1.72334 1.28564 2.26299V4.21768H16.7125V2.26299H16.7142Z"
        fill="hsl(var(--nextui-primary)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7142 6H1.28564V15.0716C1.28564 15.6239 1.73336 16.0716 2.28565 16.0716H15.7142C16.2665 16.0716 16.7142 15.6239 16.7142 15.0716V6ZM3.23132 11.0716C2.87628 11.0716 2.58847 11.3594 2.58847 11.7145C2.58847 12.0695 2.87628 12.3573 3.23132 12.3573H6.74561C7.10065 12.3573 7.38847 12.0695 7.38847 11.7145C7.38847 11.3594 7.10065 11.0716 6.74561 11.0716H3.23132ZM2.58847 9.00021C2.58847 8.64517 2.87628 8.35735 3.23132 8.35735H6.74561C7.10065 8.35735 7.38847 8.64517 7.38847 9.00021C7.38847 9.35525 7.10065 9.64307 6.74561 9.64307H3.23132C2.87628 9.64307 2.58847 9.35525 2.58847 9.00021ZM10.9285 8.35735C10.5735 8.35735 10.2856 8.64517 10.2856 9.00021C10.2856 9.35525 10.5735 9.64307 10.9285 9.64307H12.2142C12.5693 9.64307 12.8571 9.35525 12.8571 9.00021C12.8571 8.64517 12.5693 8.35735 12.2142 8.35735H10.9285Z"
        fill="currentColor"
      />
    </svg>
  );
}

import { RegularAitrade } from '@/assets/icons/comm/RegularAitrade';
import { BUSINESSRECHARGE } from '@/assets/icons/comm/BUSINESSRECHARGE';
import { Bank } from '@/assets/icons/comm/Bank';
import { INVITE } from '@/assets/icons/comm/INVITE';
import PageEnum from '@/enums/pageEnum';
import { useModel } from '@@/exports';
import { history } from '@umijs/max';
import { PersonalCenter } from '@/assets/icons/comm/PersonalCenter';
import { FlashExchange } from '@/assets/icons/comm/FlashExchange';
import { OnlineCustomerService } from '@/assets/icons/comm/OnlineCustomerService';
import { useTranslation } from 'react-i18next';
import { Loan } from '@/assets/icons/comm/Loan';
import { FINANCIAL_MANAGEMENT_LIST_ENUM } from '@/enums/businessEnum';

export default function CardList() {
  const { t } = useTranslation();
  const { getShowMenuList } = useModel('appInfo');
  const { gotoGlobalCustomerService } = useModel('imSocket');

  const cards = getShowMenuList([
    {
      key: t('邀请返佣'),
      icon: INVITE,
      path: PageEnum.INVITE,
    },
    {
      key: t('个人中心'),
      icon: PersonalCenter,
      path: PageEnum.MINE,
    },
    {
      key: t('闪兑'),
      icon: FlashExchange,
      path: PageEnum.SWAP,
    },
    {
      key: t('钱包'),
      icon: Bank,
      path: PageEnum.BANK,
    },
    {
      key: t('资金记录'),
      icon: BUSINESSRECHARGE,
      path: PageEnum.ORDER_HISTORY,
    },
    {
      key: t('理财'),
      icon: RegularAitrade,
      path: PageEnum.AITRADE + '?type=' + FINANCIAL_MANAGEMENT_LIST_ENUM.REGULAR_FINANCIAL_MANAGEMENT,
    },
    {
      key: t('贷款'),
      icon: Loan,
      path: PageEnum.LOANS,
    },
    {
      key: t('在线客服'),
      icon: OnlineCustomerService,
      path: PageEnum.CHANGEPAYMENTPASSWORD,
      onClick:  () => {
        gotoGlobalCustomerService()
      },
    },
  ]);

  return (
    <div className="my-5 px-1">
      <div className="grid grid-cols-4 gap-x-[10px] gap-y-[20px]">
        {cards.map((item: any, index: number) => (
          <div
            className={`flex flex-col items-center justify-start`}
            onClick={() => {
              item?.onClick?.();
              if (!item?.onClick) {
                history.push(item.path);
              }
            }}
            key={item.key}
          >
            <item.icon className="text-iconColor" />
            <span className="mt-2 text-center text-xs">{item.key}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

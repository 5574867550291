import { IonEyeOff } from '@/assets/icons/funds/IonEyeOff';
import { OouiEye } from '@/assets/icons/funds/OouiEye';
import BaseModal from '@/components/base/baseModal';
import FeaturesExchangeSelect from '@/components/features/featuresExchangeSelect';
import PageEnum from '@/enums/pageEnum';
import RechargeJumpModal from '@/pages/funds/index/components/RechargeJumpModal';
import ProjectSetting from '@/setting/projectSetting';
import { history } from '@@/core/history';
import { Button, Divider, useDisclosure } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'ahooks';
import { useMemoShowFunds } from '@/hooks/useMemoShowFunds';

//合约钱包
export default () => {
  const { t } = useTranslation();
  const {
    exchangeConverFunds,
    isShow,
    setIsShow,
    exchangeSetConvert,
  } = useModel('exchange');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isMobile } = useModel('system');

  const {
    renderFunds,
    contractProfitAndLoss,
  } = useModel('user');

  // 总资金
  const totalFunds = useMemoShowFunds(() => {
    return renderFunds?.overview?.total + contractProfitAndLoss;
  }, [renderFunds?.overview?.total, contractProfitAndLoss]);


  //资产、产品
  return (
    <>
      <div className="px-4  md:min-w-[20vw]  md:border-borderColor  py-1">
        <div className="flex justify-between">
          <div className="flex items-center pb-2">
            <span className="text-backContrastColor mr-2 border-b-1 border-dashed border-backgroundAuxiliaryColor ">
              {t('总资产')}
            </span>
            {isShow && (
              <OouiEye
                className="text-auxiliaryTextColor text-base"
                onClick={() => {
                  setIsShow(false);
                }}
              />
            )}
            {!isShow && (
              <IonEyeOff
                className="text-auxiliaryTextColor text-base"
                onClick={() => {
                  setIsShow(true);
                }}
              />
            )}
          </div>
        </div>

        <div className="flex items-end text-backContrastColor">
          <div className="text-xl mr-1 flex items-center">
            <div className="text-[28px]">
              {/*总资产  余额 全部 */}
              {exchangeConverFunds({
                price: totalFunds,
                defaultVal: '--', // loading 的时候显示的
              })}
            </div>

            <div className="text-sm flex items-center">
              {/* 汇率下拉 */}
              <FeaturesExchangeSelect />
            </div>
          </div>
        </div>
        <div className="text-auxiliaryTextColor">
          ≈{' '}
          {exchangeSetConvert({
            price: totalFunds,
            isJoin: true,
          })}
        </div>

        {/*<div className="text-backContrastColor mt-3 mb-3 flex items-center">*/}
        {/*  <span className="mr-1 text-sm">*/}
        {/*    {t('今日盈亏')}*/}
        {/*    <span className="ml-2">*/}
        {/*      {exchangeConverFunds({*/}
        {/*        price: tdpl,*/}
        {/*        currency: ProjectSetting.APP_DEFAULT_CURRENCY,*/}
        {/*        isShowCoinName: true,*/}
        {/*      })}*/}
        {/*    </span>*/}
        {/*  </span>*/}
        {/*  /!* <PhCaretRight className="text-auxiliaryTextColor" /> *!/*/}
        {/*</div>*/}
        {<div
          className="grid grid-cols-3 gap-x-[10px]
             mt-3 text-titleColor"
        >
          <RechargeJumpModal>
            <button className="mainColorButton text-[14px] !rounded-md h-[40px] !py-0">
              {t('充值')}
            </button>
          </RechargeJumpModal>

          <Button
            onClick={() => {
              history.push(PageEnum.WITHDRAW);
            }}
            className="mainColorButton !rounded-md h-[40px] !py-0 !text-titleColor !bg-backgroundAuxiliaryColor"
          >
            {t('提现')}
          </Button>
          <Button
            onClick={() => {
              history.push(PageEnum.TRANSFER);
            }}
            className="mainColorButton !rounded-md h-[40px] !py-0 !text-titleColor !bg-backgroundAuxiliaryColor"
          >
            {t('划转')}
          </Button>
        </div>}
      </div>

      {isMobile && <Divider className="my-4 !bg-backgroundAuxiliaryColor" />}

      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        placement="center"
        title={t('提示')}
      >
        <div className="w-[300px] px-4 pb-4 text-foreground">
          {t(
            '在合约市场中，当投资者提交买入委托但未成交时，用于该委托的预估资金会被暂时冻结，直到委托被撤销或成交。如果委托在当天未撤销且未成交，冻结状态会在下一个交易日解除。',
          )}
        </div>
      </BaseModal>
    </>
  );
};
